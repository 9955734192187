import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">

            <section>
            <header className="major">
                <h2>Kontakt</h2>
            </header>
                <form action="https://getform.io/f/1fba8314-ddb6-4b87-a489-c1cb8b1a1f6c" method="POST" acceptCharset="UTF-8">
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea minLength="20" name="message" id="message" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:guelkebappizzahaus@gmail.com">guelkebappizzahaus@gmail.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Tel</h3>
                        <a href="tel:00496325184949">06325184949</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>Hauptstraße 95 <br /> 67466 Lambrecht (Pfalz)</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-clock-o"></span>
                        <h3>Öffnungszeiten</h3>
                        <p>
                            Mo.: Ruhetag <br />
                            Di. - Sa. : 11.00 – 22.00 Uhr <br />
                            So. & Feiertag : 12.00 – 22.00 Uhr
                        </p>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
