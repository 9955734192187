import React from 'react'
import PropTypes from 'prop-types'
import Stickyheader from 'react-sticky-header';
import 'react-sticky-header/styles.css';


const Header = (props) => (
    <Stickyheader header={
        <header id="header"  className="alt" style={{backgroundColor:'rgba(0, 0, 0, 0.3)'}}>
            <a href="/" className="logo"><strong>GÜL</strong></a>
            <nav>
                <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
            </nav>
        </header>
    }
    >
    </Stickyheader>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
