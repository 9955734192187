import React from 'react'
import PropTypes from 'prop-types'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><a onClick={props.onToggleMenu} href="/">Home</a></li>
                <li><a onClick={props.onToggleMenu} href="#opening">Öffnungszeiten</a></li>
                <li><a onClick={props.onToggleMenu} href="#contact">Kontakt</a></li>
                <li><div onClick={props.onToggleMenu} className="button  special fit order-button"  data-glf-cuid="534c17d7-7092-4552-b6f2-8a0c4295937a" data-glf-ruid="60266ba6-c731-4b67-9842-e4760318ff07" >Karte & Bestellen</div></li>
                {/* <li>-</li>
                <li><a onClick={props.onToggleMenu} href="/privacy">Öffnungszeiten</a></li>
                <li><a onClick={props.onToggleMenu} href="#contact">Kontakt</a></li> */}
            </ul>
        
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
