import React from 'react'
import Helmet from 'react-helmet'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
    require("cookieconsent");
  }
  

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { children } = this.props

        return (
            <div>
                <Helmet
                    title="Gül Kebap Und Pizza Haus Lambrecht(Pfalz) - Essen zum Mitnehmen - Lambrecht (Pfalz) - Online bestellen"
                    meta={[
                        { name: 'description', content: 'Bestellen Sie online zum Mitnehmen. Bei Gül Kebap Und Pizza Haus Lambrecht(Pfalz) in Lambrecht (Pfalz) erleben Sie köstliche Pizza, Sandwiches, Salads, Fast Food, Kebab, Turkish Küche. Probieren Sie unsere köstlichen Gerichte aus, die sorgfältig aus frischen Zutaten zubereitet werden! Bei Gül Kebap Und Pizza Haus Lambrecht(Pfalz) ist unser Erfolgsrezept einfach - Großartiges Essen & Sorgfalt lässt Kunden immer wieder kommen.' },
                        { name: 'keywords', content: 'Gül Döner, Gül Pizza, Döner, Pizza, Lahmacun, Pide, Salat, Falalafel, Döner Teller, Pizza in der Nähe, Pizzeria Lambrecht(Pfalz), Döner Lambrecht(Pfalz), Döner pizza, Pizza Bestellen, Heimservice, Lieferservice, Döner in der nähe, Lambrecht Döner, Lambrecht Pizza, Lambrecht Pide, Lambrecht Salat, Delivery near me, Lieferdienst' },
                    ]}
                    
                    
                >
                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css" />
                    <script type='application/ld+json'>{`
                        {
                            "@context": "http://schema.org/",
                            "@type": "FastFoodRestaurant",
                            "acceptsReservations": "https://www.guelkebappizzahaus.com/",
                            "name": "Gül Kebap und Pizza Haus Lambrecht(Pfalz)",
                            "url": "https://www.guelkebappizzahaus.com/",
                            "servesCuisine": [
                                "Döner",
                                "Pizza",
                                "Pide",
                                "Lahmacun",
                                "Salat",
                                "Schnitzel",
                                "Kebap",
                                "Gätränke",
                                "Kaffee",
                                "Pommes",
                                "Snacks"
                            ],
                            "currenciesAccepted": "Euro",
                            "openingHours": "Monday\t11AM–10PM,, Tuesday\t11AM–10PM, Wednesday\t11AM–10:30PM, Thursday\t11AM–10:30PM, Friday\t11AM–10:30PM, Saturday\t11AM–10:30PM, Sunday and Holidays\t12–10:30PM",
                            "paymentAccepted": "Cash, Credit Card",
                            "priceRange": "$$",
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": "Germany",
                                "addressLocality": "Lambrecht (Pfalz)",
                                "addressRegion": "Bad Dürkheim",
                                "postalCode": "67466",
                                "streetAddress": "Hauptstraße 95",
                                "telephone": "06325 184949",
                                "name": "Gül Kebap und Pizza Haus Lambrecht(Pfalz)"
                            },
                            "brand": "Gül Kebab Pizza Haus",
                            "founder": "Mustafa Gül",
                            "logo": {
                                "@type": "ImageObject",
                                "caption": "Gül Kebab Pizza Haus",
                                "embedUrl": "https://www.guelkebappizzahaus.com/",
                                "encodingFormat": "png"

                            
                            }
                        }
                    `}</script>
                     <script src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js" data-cfasync="false"></script>
                    <script>{`
                    
                    window.cookieconsent.initialise({
                    "palette": {
                        "popup": {
                        "background": "#ffffff",
                        "text": "#1f1d1d"
                        },
                        "button": {
                        "background": "#0a0a0a",
                        "text": "#fffefe"
                        }
                    },
                    "position": "bottom-left",
                    "content": {
                        "message": "Um sicherzustellen, dass unsere Website einwandfrei funktioniert und um diese fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in ",
                        "dismiss": "Verstanden",
                        "link": "details",
                        "href": "http://guelkebappizzahaus.com/privacy"
                    }
                    });
                    `}
                    </script>
                    <script src="https://www.fbgcdn.com/embedder/js/ewm2.js" defer async ></script>
                    <html lang="en" />

                </Helmet>
                
                <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id="wrapper">
                    <Header onToggleMenu={this.handleToggleMenu} />
                    {children}
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
            </div>
            </div>
        )
    }
}

export default Layout
